<template>
  <div class="w100 bs flex" style="padding-bottom:20px;">
    <div class="look-left" style="border-radius: 2px">
      <el-menu :default-active="dfAC" :default-openeds="['0', '1', '2']" @select="menuselect">
        <template v-for="(item, index) in lookNav" :key="index">
          <el-submenu :index="String(index)">
            <template #title>
              <span>{{ item.title }}</span>
            </template>
            <el-menu-item v-for="(childrenItem, childrenIndex) in item.children" :key="childrenIndex"
              :index="`${index}-${childrenIndex}`">{{ childrenItem.title
              }}{{
    childrenItem.tog ? `（${childrenItem.tog}）` : ""
}}</el-menu-item>
          </el-submenu>
        </template>
      </el-menu>
    </div>
    <div class="flex-sub look-right">
      <div class="look-right-title w100 bs flex align-center">{{ tabTitle }}</div>
      <div class="w100 bs" style="padding: 24px" v-loading="showloading">
        <div class="look-right-s w100 flex align-center">
          <el-input v-model="paramObj.resumeKeyword" clearable placeholder="请输入简历关键词" class="input-with-select"></el-input>
          <el-button type="primary" @click="kwClick" style="padding: 0 20px; margin-left: 4px">
            <div class="el-icon-search text-20"></div>
          </el-button>
        </div>
        <div class="look-right-condition-box">
          <div class="flex justify-between" style="margin-bottom: 14px">
            <div class="shrink_0 l_r_cli flex align-center">
              <div class="text-14 c_101010">最近公司：</div>
              <el-input v-model="paramObj.formerCompany" placeholder="请输入最近公司" class="flex-sub"></el-input>
            </div>
            <div class="shrink_0 l_r_cli flex align-center">
              <div class="text-14 c_101010">居住城市：</div>
              <el-cascader v-model="FORM.residentialCity" clearable :options="cityOp" @change="liveCityChange"
                :show-all-levels="false" />
            </div>
            <div class="shrink_0 l_r_cli flex align-center">
              <div class="text-14 c_101010">期望城市：</div>
              <el-cascader v-model="FORM.expectCity" clearable :options="cityOp" @change="expectCityChange"
                :show-all-levels="false" />
            </div>
          </div>
          <div class="flex justify-between" style="margin-bottom: 14px">
            <div class="shrink_0 l_r_cli flex align-center">
              <div class="text-14 c_101010">期望月薪：</div>
              <el-select class="flex-sub" clearable v-model="FORM.mSalary" @change="salaryChange" placeholder="请选择薪资范围"
                size="large">
                <el-option v-for="(item, index) in mSalaryOp" :key="index" :label="item.label" :value="index" />
              </el-select>
            </div>
            <div class="shrink_0 l_r_cli flex align-center">
              <div class="text-14 c_101010">工作经验：</div>
              <el-select class="flex-sub" clearable v-model="paramObj.experience" @change="getInfo"
                placeholder="请选择工作经验" size="large">
                <el-option v-for="item in experiencenOp" :key="item.code" :label="item.name" :value="item.code" />
              </el-select>
            </div>

            <div class="shrink_0 l_r_cli flex align-center">
              <div class="text-14 c_101010">学历要求：</div>
              <el-select class="flex-sub" clearable v-model="paramObj.education" @change="getInfo" placeholder="请选择学历要求"
                size="large">
                <el-option v-for="item in educationOp" :key="item.code" :label="item.name" :value="item.code" />
              </el-select>
            </div>
          </div>
          <div class="flex">
            <div class="shrink_0 l_r_cli flex align-center">
              <div class="text-14 c_101010">性别要求：</div>
              <el-select class="flex-sub" clearable v-model="paramObj.gender" @change="getInfo" placeholder="请选择性别"
                size="large">
                <el-option v-for="item in genderOp" :key="item.code" :label="item.name" :value="item.code" />
              </el-select>
            </div>
          </div>
        </div>

        <div class="w100 bs flex align-center justify-between" style="padding-top: 16px;">
          <div>
            <div class="flex align-center" v-if="(initMenu[0]==2&&initMenu[1]==1)?true:false">
              <el-radio-group v-model="radio1" class="ml-4">
                <el-radio :label="index" size="large" v-for="(item, index) in matchStateList" :key="index">{{ item }}
                </el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="text-14">总计<span class="c_3291F8">{{ totalCount }}</span>位人选</div>
        </div>
        <div v-if="TOWLIST.length == 0" style="margin: 100px 0;">
          <nodatadf />
        </div>
        <div class="w100 bs h-two-li cur-p flex" v-for="(item, index) in TOWLIST" :key="index" @click="goDetail(index)">
          <el-image style="width: 64px; height: 72px"  class="h-two-li-icon shrink_0" :src="item.photo ? item.photo : ''" fit="contain" />
          <div class="flex-sub" style="margin-left: 16px">
            <el-row justify="space-between">
              <el-col :span="20">
                <div class="flex align-center text-14" style="line-height: 26px">
                  <div class="c_3291F8">{{ item.name }}</div>
                  <div class="etreqmg1">|</div>
                  {{ item.gender }}
                  <div class="etreqmg1">|</div>
                  {{ item.age }}岁
                  <div class="etreqmg1">|</div>
                  {{ item.liveCity }}
                  <div class="etreqmg1">|</div>
                  <!-- 4年 -->
                </div>
                <div class="text-14 c_text_c" style="line-height: 26px">
                  <div class="flex align-center" v-if="item.education">
                    <span>最高学历：</span>{{item.education.startTime}} - {{item.education.endTime}}
                    <div class="etreqmg1">|</div>
                    <span>{{item.education.name}}</span>
                    <div class="etreqmg1">|</div>
                    {{item.education.ext.education}}
                  </div>
                  <div class="flex" v-if="item.workList && item.workList.length > 0">
                    <div class="shrink_0">最近工作：</div>
                    <div class="flex-sub">
                      <div class="flex align-center" v-for="(workItem, workIndex) in item.workList" :key="workIndex">
                        {{ workItem.startTime }} - {{ workItem.endTime }}
                        <div class="etreqmg1">|</div>
                        <span>{{ workItem.company }}</span>
                        <div class="etreqmg1">|</div>
                        {{ workItem.job }}
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="flex flex-direction text-14 align-end">
                  <div v-if="initMenu[0] == 0" class="c_595959 text-14" style="line-height: 35px;">刚刚活跃</div>
                  <el-button type="primary" :loading="item.SHOWLOADING" @click.stop="showChat(index)">和他聊聊</el-button>
                  <!-- <div style="margin: 10px 0">看过的职位：</div>
                  <div class="c_3291F8">技术开发</div> -->
                </div>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="mt_20 w100 bs flex justify-end">
          <el-pagination lazy layout="total ,prev,pager,next,sizes,jumper" :total="totalCount"
            @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="psArr" style="font-size: 20px">
          </el-pagination>

        </div>
      </div>
    </div>
    <dfpop ref="dfpopref" @confirm="popconfirm" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { listSeekerResumeInfo, listJobApply, listJobHunterView, listJobSeekerView, listCity, salaryOption, listDict, createChatInfo, listHunterJob } from '@/util/apis'
import { ElLoading, ElMessage } from 'element-plus'
import dfpop from "@/views/components/dfPop.vue"
import router from "@/router";
export default defineComponent({
  data() {
    return {
      lookNav: [
        {
          title: "坪山天安人才库",
          children: [{ title: "优质人才", tog: "" }],
        },
        {
          title: "我的人才库",
          children: [{ title: "我的人才", tog: "" }],
        },
        {
          title: "人才来源",
          children: [
            { title: "我看过的", tog: "" },
            { title: "应聘的简历" },
            { title: "看过我的", tog: "" },
          ],
        },
      ],
      FORM: {
        residentialCity: "",
        expectCity: "",
        mSalary: "",
      },
      cityOp: [],
      educationOp: [] as any,
      experiencenOp: [] as any,
      genderOp: [] as any,
      mSalaryOp: [] as any,
      TOWLIST: [] as any,

      totalCount: 0,
      currentPage: 1,
      pagesize: 10,
      initMenu: [0, 0],
      psArr: [10, 20, 30],
      paramObj: {
        resumeKeyword: '',//简历关键词
        formerCompany: '', //就职过的公司
        liveCity: '', //居住城市
        intendCity: '',//期望工作城市
        intendJobCategory: '',  //期望职位类别
        intendSalary: '' as any,//期望月薪
        experience: '',//经验年限
        education: '',//学历
        gender: '',//性别
        matchState: '',//应聘状态
        applyWay: ''//投递方式
      },
      showloading: true,
      jobList: [] as any,
      ClickIndex: -1 as any,
      matchStateList: ['待反馈', '待定', '合适', '不合适'],
      radio1:0,
      dfAC:'0-0'
    };
  },
  computed: {
    tabTitle(): any {
      return this.lookNav[this.initMenu[0]].children[this.initMenu[1]].title
    }
  },
  components: {
    dfpop
  },
  watch:{
    radio1(newVal:any,oldVal:any){
      console.log('newVal',newVal)
      this.getInfo();
    }
  },
  mounted() {
    this.mSalaryOp = salaryOption();
    //学历要求 经验年限 性别 省市
    var doArr = ['job_education', 'job_experience', 'gender'].map(item => listDict({ type: item }))
    Promise.all([...doArr, listCity({ type: '2' })]).then(res => {
      this.educationOp = res[0].data;
      this.experiencenOp = res[1].data;
      this.genderOp = res[2].data;
      this.cityOp = res[3].data;
      if(router.currentRoute.value.params.cusMenu){
        this.initMenu = router.currentRoute.value.params.cusMenu.toString().split('-').map(item=>Number(item));
        this.dfAC = this.initMenu[0] + '-' + this.initMenu[1];
        this.getInfo();
      }else{
        if(this.initMenu[0]==0&&this.initMenu[1]==0){
        this.getYz();
      }
      }
      this.showloading = false;
    }).catch(f => {
      this.showloading = false;
    })
    listHunterJob({
      page: this.currentPage,
      pageSize: this.pagesize,
      state: '1',
    }).then(res => {
      res.data.list.forEach((element: any) => {
        element.cusCity = element.ext.cityList.map((item: any) => item.remarks).join('、')
      });
      this.jobList = res.data.list;
    })
  },
  methods: {
    handleSizeChange(size: number) {
      this.pagesize = size;
      this.getInfo();
    },

    handleCurrentChange(currentPage: number) {
      this.currentPage = currentPage;
      this.getInfo();
    },
    liveCityChange(e: any) {
      this.paramObj.liveCity = e && e.length > 1 ? e[1] : '';
      this.getInfo();
    },
    expectCityChange(e: any) {
      this.paramObj.intendCity = e && e.length > 1 ? e[1] : '';
      this.getInfo();
    },
    kwClick(){
      this.getInfo();
    },
    salaryChange(e: any) {
      // this.paramObj.intendSalary = [{ salaryMin: this.mSalaryOp[e].salaryMin, salaryMax: this.mSalaryOp[e].salaryMax }]
      this.paramObj.intendSalary = e && e>=0 ? this.mSalaryOp[e].salaryMax : '';
      this.getInfo();
    },
    getYz() {
      Object.keys(this.paramObj).map(key => ((!this.paramObj[key] && this.paramObj[key] !== 0) || this.paramObj[key] == -1 ? delete this.paramObj[key] : ''))
      listSeekerResumeInfo({
        page: this.currentPage,
        pageSize: this.pagesize,
        ...this.paramObj
      }).then(res => {
        res.data.resumeList.forEach((element: any) => {
          element.SHOWLOADING = false;
        });
        this.TOWLIST = res.data.resumeList;
        this.totalCount = res.data.totalCount;
        this.showloading = false;
      })
    },
    getM() {
      if(this.initMenu[0]==2&&this.initMenu[1]==1){
        this.paramObj.matchState = String(this.radio1);
      }else{
        this.paramObj.matchState = '';
      }
      
      listJobApply({
        page: this.currentPage,
        pageSize: this.pagesize,
        state: (this.initMenu[0]==2&&this.initMenu[1]==1)?this.radio1:'',
        ...this.paramObj
      }).then(res => {
        res.data.resumeList.forEach((element: any) => {
          element.SHOWLOADING = false;
        });
        this.TOWLIST = res.data.resumeList;
        this.totalCount = res.data.totalCount;
        this.showloading = false;
      })
    },
    getMlook() {
      listJobHunterView({
        page: this.currentPage,
        pageSize: this.pagesize,
        ...this.paramObj
      }).then(res => {
        res.data.resumeList.forEach((element: any) => {
          element.SHOWLOADING = false;
        });
        this.TOWLIST = res.data.resumeList;
        this.totalCount = res.data.totalCount;
        this.showloading = false;
      })
    },
    getLookM() {
      listJobSeekerView({
        page: this.currentPage,
        pageSize: this.pagesize,
        ...this.paramObj
      }).then(res => {
        res.data.resumeList.forEach((element: any) => {
          element.SHOWLOADING = false;
        });
        this.TOWLIST = res.data.resumeList;
        this.totalCount = res.data.totalCount;
        this.showloading = false;
      })
    },
    getInfo() {
      this.showloading = true;
      Object.keys(this.paramObj).map(key => ((!this.paramObj[key] && this.paramObj[key] !== 0) || this.paramObj[key] == -1 ? delete this.paramObj[key] : ''))
      switch (Number(this.initMenu[0])) {
        case 0:
          this.getYz();
          break;
        case 1:
          this.getM();
          break;
        case 2:
          switch (Number(this.initMenu[1])) {
            case 0:
              this.getMlook();
              break;
            case 1:
              this.getM();
              break;
            case 2:
              this.getLookM();
              break;
          }
          break;
      }
    },
    menuselect(e: any) {
      console.log('e',e);
      this.initMenu = e.split('-');
      this.getInfo();
    },
    showChat(ClickIndex: any) {
      (this.$refs.dfpopref as any).show(this.jobList);
      this.ClickIndex = ClickIndex;
    },
    popconfirm(i: any) {
      let ITEM: any = this.TOWLIST[this.ClickIndex]
      createChatInfo({
        seekerUserId: ITEM.userId, jobId: this.jobList[i].id
      }).then(res => {
        if (res.code == 0) {
          if(res.data && res.data.id){
            router.push({ path: "/news/index",name:'news',params:{id:res.data.id} });
            return
          }
          router.push({ path: "/news/index" });
        }
      })
    },
    goDetail(i: any) {
      router.push({ path: "/resume/index", name: 'resume', params: { id: this.TOWLIST[i].resumeId } });
    }
  },
  setup: () => { },
});
</script>
<style scoped >
@import "./index.css";
</style>
