
import { defineComponent } from "vue";
import { listSeekerResumeInfo, listJobApply, listJobHunterView, listJobSeekerView, listCity, salaryOption, listDict, createChatInfo, listHunterJob } from '@/util/apis'
import { ElLoading, ElMessage } from 'element-plus'
import dfpop from "@/views/components/dfPop.vue"
import router from "@/router";
export default defineComponent({
  data() {
    return {
      lookNav: [
        {
          title: "坪山天安人才库",
          children: [{ title: "优质人才", tog: "" }],
        },
        {
          title: "我的人才库",
          children: [{ title: "我的人才", tog: "" }],
        },
        {
          title: "人才来源",
          children: [
            { title: "我看过的", tog: "" },
            { title: "应聘的简历" },
            { title: "看过我的", tog: "" },
          ],
        },
      ],
      FORM: {
        residentialCity: "",
        expectCity: "",
        mSalary: "",
      },
      cityOp: [],
      educationOp: [] as any,
      experiencenOp: [] as any,
      genderOp: [] as any,
      mSalaryOp: [] as any,
      TOWLIST: [] as any,

      totalCount: 0,
      currentPage: 1,
      pagesize: 10,
      initMenu: [0, 0],
      psArr: [10, 20, 30],
      paramObj: {
        resumeKeyword: '',//简历关键词
        formerCompany: '', //就职过的公司
        liveCity: '', //居住城市
        intendCity: '',//期望工作城市
        intendJobCategory: '',  //期望职位类别
        intendSalary: '' as any,//期望月薪
        experience: '',//经验年限
        education: '',//学历
        gender: '',//性别
        matchState: '',//应聘状态
        applyWay: ''//投递方式
      },
      showloading: true,
      jobList: [] as any,
      ClickIndex: -1 as any,
      matchStateList: ['待反馈', '待定', '合适', '不合适'],
      radio1:0,
      dfAC:'0-0'
    };
  },
  computed: {
    tabTitle(): any {
      return this.lookNav[this.initMenu[0]].children[this.initMenu[1]].title
    }
  },
  components: {
    dfpop
  },
  watch:{
    radio1(newVal:any,oldVal:any){
      console.log('newVal',newVal)
      this.getInfo();
    }
  },
  mounted() {
    this.mSalaryOp = salaryOption();
    //学历要求 经验年限 性别 省市
    var doArr = ['job_education', 'job_experience', 'gender'].map(item => listDict({ type: item }))
    Promise.all([...doArr, listCity({ type: '2' })]).then(res => {
      this.educationOp = res[0].data;
      this.experiencenOp = res[1].data;
      this.genderOp = res[2].data;
      this.cityOp = res[3].data;
      if(router.currentRoute.value.params.cusMenu){
        this.initMenu = router.currentRoute.value.params.cusMenu.toString().split('-').map(item=>Number(item));
        this.dfAC = this.initMenu[0] + '-' + this.initMenu[1];
        this.getInfo();
      }else{
        if(this.initMenu[0]==0&&this.initMenu[1]==0){
        this.getYz();
      }
      }
      this.showloading = false;
    }).catch(f => {
      this.showloading = false;
    })
    listHunterJob({
      page: this.currentPage,
      pageSize: this.pagesize,
      state: '1',
    }).then(res => {
      res.data.list.forEach((element: any) => {
        element.cusCity = element.ext.cityList.map((item: any) => item.remarks).join('、')
      });
      this.jobList = res.data.list;
    })
  },
  methods: {
    handleSizeChange(size: number) {
      this.pagesize = size;
      this.getInfo();
    },

    handleCurrentChange(currentPage: number) {
      this.currentPage = currentPage;
      this.getInfo();
    },
    liveCityChange(e: any) {
      this.paramObj.liveCity = e && e.length > 1 ? e[1] : '';
      this.getInfo();
    },
    expectCityChange(e: any) {
      this.paramObj.intendCity = e && e.length > 1 ? e[1] : '';
      this.getInfo();
    },
    kwClick(){
      this.getInfo();
    },
    salaryChange(e: any) {
      // this.paramObj.intendSalary = [{ salaryMin: this.mSalaryOp[e].salaryMin, salaryMax: this.mSalaryOp[e].salaryMax }]
      this.paramObj.intendSalary = e && e>=0 ? this.mSalaryOp[e].salaryMax : '';
      this.getInfo();
    },
    getYz() {
      Object.keys(this.paramObj).map(key => ((!this.paramObj[key] && this.paramObj[key] !== 0) || this.paramObj[key] == -1 ? delete this.paramObj[key] : ''))
      listSeekerResumeInfo({
        page: this.currentPage,
        pageSize: this.pagesize,
        ...this.paramObj
      }).then(res => {
        res.data.resumeList.forEach((element: any) => {
          element.SHOWLOADING = false;
        });
        this.TOWLIST = res.data.resumeList;
        this.totalCount = res.data.totalCount;
        this.showloading = false;
      })
    },
    getM() {
      if(this.initMenu[0]==2&&this.initMenu[1]==1){
        this.paramObj.matchState = String(this.radio1);
      }else{
        this.paramObj.matchState = '';
      }
      
      listJobApply({
        page: this.currentPage,
        pageSize: this.pagesize,
        state: (this.initMenu[0]==2&&this.initMenu[1]==1)?this.radio1:'',
        ...this.paramObj
      }).then(res => {
        res.data.resumeList.forEach((element: any) => {
          element.SHOWLOADING = false;
        });
        this.TOWLIST = res.data.resumeList;
        this.totalCount = res.data.totalCount;
        this.showloading = false;
      })
    },
    getMlook() {
      listJobHunterView({
        page: this.currentPage,
        pageSize: this.pagesize,
        ...this.paramObj
      }).then(res => {
        res.data.resumeList.forEach((element: any) => {
          element.SHOWLOADING = false;
        });
        this.TOWLIST = res.data.resumeList;
        this.totalCount = res.data.totalCount;
        this.showloading = false;
      })
    },
    getLookM() {
      listJobSeekerView({
        page: this.currentPage,
        pageSize: this.pagesize,
        ...this.paramObj
      }).then(res => {
        res.data.resumeList.forEach((element: any) => {
          element.SHOWLOADING = false;
        });
        this.TOWLIST = res.data.resumeList;
        this.totalCount = res.data.totalCount;
        this.showloading = false;
      })
    },
    getInfo() {
      this.showloading = true;
      Object.keys(this.paramObj).map(key => ((!this.paramObj[key] && this.paramObj[key] !== 0) || this.paramObj[key] == -1 ? delete this.paramObj[key] : ''))
      switch (Number(this.initMenu[0])) {
        case 0:
          this.getYz();
          break;
        case 1:
          this.getM();
          break;
        case 2:
          switch (Number(this.initMenu[1])) {
            case 0:
              this.getMlook();
              break;
            case 1:
              this.getM();
              break;
            case 2:
              this.getLookM();
              break;
          }
          break;
      }
    },
    menuselect(e: any) {
      console.log('e',e);
      this.initMenu = e.split('-');
      this.getInfo();
    },
    showChat(ClickIndex: any) {
      (this.$refs.dfpopref as any).show(this.jobList);
      this.ClickIndex = ClickIndex;
    },
    popconfirm(i: any) {
      let ITEM: any = this.TOWLIST[this.ClickIndex]
      createChatInfo({
        seekerUserId: ITEM.userId, jobId: this.jobList[i].id
      }).then(res => {
        if (res.code == 0) {
          if(res.data && res.data.id){
            router.push({ path: "/news/index",name:'news',params:{id:res.data.id} });
            return
          }
          router.push({ path: "/news/index" });
        }
      })
    },
    goDetail(i: any) {
      router.push({ path: "/resume/index", name: 'resume', params: { id: this.TOWLIST[i].resumeId } });
    }
  },
  setup: () => { },
});
